<template>
  <div>
    <DisplayTotalization title="Sub-Total" :value="totalization.totalValue" />
    <DisplayTotalization
      v-if="totalization.totalDiscount > 0"
      title="Desconto"
      :value="totalization.totalDiscount"
      _style="color: red"
    />
    <DisplayTotalization
      title="TOTAL GERAL"
      :value="totalization.totalGeneral"
      _style="color: #1b3a55;font-size:19px"
    />
  </div>
</template>
<script>
import DisplayTotalization from "@nixweb/nixloc-ui/src/component/rental/DisplayTotalization";

import { mapActions, mapState } from "vuex";

export default {
  name: "InvoiceTotalization",
  components: { DisplayTotalization },
  props: {
    invoiceId: String,
  },
  data() {
    return {
      urlGetTotalization: "/api/v1/billing/invoice/totalization",
      totalization: {},
    };
  },
  created() {
    this.getTotalization();
  },
  computed: {
    ...mapState("generic", ["event"]),
  },
  methods: {
    ...mapActions("generic", ["getApi"]),
    getTotalization() {
      let params = {
        url: this.urlGetTotalization,
        obj: { invoiceId: this.invoiceId },
      };
      this.getApi(params).then((response) => {
        this.totalization = response.content;
      });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "updateTotalizationInvoice") this.getTotalization();
      },
      deep: true,
    },
  },
};
</script>
<style scoped></style>
