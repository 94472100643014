export default class ItemsInvoice {
    constructor() {
        this.id = "";
        this.description = "";
        this.quantity = 1;
        this.unitValue = 0;
        this.discount = { discountByPercentage: false, discount: 0 };
        this.discountByPercentage = false;
        this.unitDiscount = 0;
        this.invoiceId = "";
    }
    update(data) {
        this.id = data.id;
        this.description = data.description;
        this.quantity = data.quantity;
        this.unitValue = data.unitValue;
        this.discount = { discountByPercentage: false, discount: data.unitDiscount };
        this.invoiceId = data.invoiceId;
    }
}