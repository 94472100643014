<template>
  <div>
    <Loading type="line" :center="false" v-show="loading" />
    <b-row>
      <b-col sm="12">
        <div>
          <EditorHtml title="Descrição" field="description" :required="false" :disabled="disabled"
            :markFormDirty="false" :maxLength="500" v-model="itemsInvoice.description" />
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="2">
        <InputNumber title="Quantidade" field="quantity" :formName="formName" :required="false" :disabled="disabled"
          :markFormDirty="false" :maxLength="5" type="float" v-model="itemsInvoice.quantity" />
      </b-col>
      <b-col sm="3">
        <InputDecimal title="Valor Unitário" field="unitValue" :formName="formName" :required="false"
          :disabled="disabled" :markFormDirty="false" :maxLength="5" :precision="10" type="float"
          v-model="itemsInvoice.unitValue" />
      </b-col>
      <b-col sm="3">
        <InputDecimalDiscount title="Desconto Unitário" field="unitDiscount" :formName="formName" :required="false"
          :disabled="disabled" _style="color:red;" :maxLength="5" :precision="10" type="float"
          v-model="itemsInvoice.discount" />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <div class="div-btn text-right">
          <Button _key="btnCreateUpdateItemInvoice" type="success" title="Salvar"
            :disabled="!isFormValid(formName) || disabled" classIcon="fas fa-save" size="medium"
            :clicked="saveItemInvoice" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>


<script>
import InputDecimalDiscount from "@nixweb/nixloc-ui/src/component/forms/InputDecimalDiscount";
import InputNumber from "@nixweb/nixloc-ui/src/component/forms/InputNumber";
import InputDecimal from "@nixweb/nixloc-ui/src/component/forms/InputDecimal";
import EditorHtml from "@nixweb/nixloc-ui/src/component/forms/EditorHtml";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";

import ItemsInvoice from "./ItemsInvoice.js";

import { mapActions, mapMutations, mapGetters } from "vuex";

export default {
  name: "ItemsInvoiceCreateUpdate",
  props: {
    invoiceId: String,
    disabled: Boolean,
  },
  components: {
    EditorHtml,
    InputDecimalDiscount,
    InputNumber,
    InputDecimal,
    Button,
    Loading,
  },
  data() {
    return {
      formName: "itemsInvoice",
      itemsInvoice: new ItemsInvoice(),
      loading: false,
      urlCreate: "/api/v1/billing/items-invoice/create",
      urlUpdate: "/api/v1/billing/items-invoice/update",
    };
  },
  computed: {
    ...mapGetters("generic", ["event"]),
    ...mapGetters("validation", ["isFormValid"]),
  },
  methods: {
    ...mapActions("generic", ["postApi", "putApi"]),
    ...mapMutations("generic", ["addEvent", "removeLoading", "hideModal"]),
    saveItemInvoice() {
      let discount = this.itemsInvoice.discount;
      this.itemsInvoice.discountByPercentage = discount.discountByPercentage;
      this.itemsInvoice.unitDiscount = discount.discount;
      this.itemsInvoice.invoiceId = this.invoiceId;

      if (this.itemsInvoice.id) {
        let params = { url: this.urlUpdate, obj: this.itemsInvoice };
        this.putApi(params).then((response) => {
          if (response.success) {
            this.addEvent({
              name: "addedProductInvoice",
            });
            this.addEvent({ name: "updateTotalizationInvoice" });
            this.hideModal();
          }
          this.removeLoading(["btnCreateUpdateItemInvoice"]);
        });
      } else {
        let params = { url: this.urlCreate, obj: this.itemsInvoice };
        this.postApi(params).then((response) => {
          if (response.success) {
            this.addEvent({ name: "updateTotalizationInvoice" });
            this.hideModal();
          }
          this.removeLoading(["btnCreateUpdateItemInvoice"]);
        });
      }
    },
    update(data) {
      this.itemsInvoice = new ItemsInvoice();
      this.itemsInvoice.update(data);
      this.loading = true;
      let self = this;
      setTimeout(function () {
        self.loading = false;
      }, 200);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "itemInvoiceUpdate") {
          this.update(event.data);
          this.loading = true;
          let self = this;
          setTimeout(function () {
            self.loading = false;
          }, 300);
        }
      },
      deep: true,
    },
  },
};
</script>