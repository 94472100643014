<template>
  <div class="side-by-side">
    <Dropdown title="Opções" type="primary" size="small" classIcon="fa-solid fa-chevron-down" right="50"
      :items="optionsConditional" />
    <Modal :title="modalOptions.title" :width="modalOptions.width" v-if="showModal('optionsRent')"
      :onHideModal="closeModal">
      <div v-if="modalOptions.name == 'generateInvoice'">
        <Alert type="warning">
          <span>
            Atenção, certifique-se que está gerando a Fatura correta, após
            gerada não será permitido alterações!
          </span>
        </Alert>
        <Confirmation :isModal="false" title="Você tem certeza?" type="primary" :confirmed="generate" />
      </div>
      <div v-if="modalOptions.name == 'cancelInvoice'">
        <Alert type="warning">
          <span>
            Atenção, ao cancelar uma Fatura alguns processos (se houver) serão executados, certifique-se que está
            cancelando
            a Fatura correta, esta ação é irreversível...
          </span>
        </Alert>
        <div>
          <i class="fa-solid fa-circle-xmark icon-close"></i> <span class="title">Fatura ficará com status de
            Cancelada</span>
        </div>
        <div>
          <i class="fa-solid fa-circle-xmark icon-close"></i><span class="title">Pagamentos vinculados serão
            excluídos</span>
        </div>
        <div>
          <i class="fa-solid fa-triangle-exclamation icon-warning"></i><span class="title">Produtos da Locação
            serão liberados para novo Faturamento
          </span>
        </div>
        <Confirmation :isModal="false" title="Você tem certeza?" type="danger" :confirmed="cancel" />
      </div>
      <div class="select-document" v-show="modalOptions.name == 'selectDocument'"></div>
    </Modal>
    <PrintPreview v-if="showModal('print')" module="Billing" :invoiceId="invoice.id" />
  </div>
</template>
<script>
import Dropdown from "@nixweb/nixloc-ui/src/component/forms/Dropdown.vue";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";
import Confirmation from "@nixweb/nixloc-ui/src/component/shared/Confirmation.vue";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";
import PrintPreview from "../../adm/document/PrintPreview.vue";

import { mapGetters, mapMutations, mapActions, mapState } from "vuex";

export default {
  name: "DropdownOptionsInvoice",
  props: {
    invoice: Object,
  },
  components: {
    Dropdown,
    Modal,
    Select,
    Confirmation,
    Alert,
    PrintPreview,
  },
  data() {
    return {
      documentId: "",
      urlGenerateAllApi: "/api/v1/billing/invoice/generate",
      urlCancelAllApi: "/api/v1/billing/invoice/cancel",
      modalOptions: {
        name: "",
        title: "",
        width: 0,
      },
      options: [
        {
          showByStatus: [3],
          title: "Gerar",
          classIcon: "fa-solid fa-retweet",
          eventName: "generateInvoice",
          style: "color:#007BFF;",
          hr: true,
        },
        {
          showByStatus: [1, 2],
          title: "Compartilhar",
          classIcon: "fa-sharp fa-regular fa-share-nodes",
          eventName: "printInvoice",
          hr: true,
        },
        {
          showByStatus: [1],
          key: "cancel",
          title: "Cancelar",
          classIcon: "fa-solid fa-ban",
          style: "color:red;",
          eventName: "cancelInvoice",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("generic", ["showModal", "event"]),
    ...mapState("user", ["userLogged"]),
    ...mapGetters("user", ["hasRule"]),
    optionsConditional() {
      let options = [];
      this.options.forEach((option) => {
        if (option.showByStatus.includes(this.invoice.status))
          options.push(option);
      });

      return options;
    },
  },
  methods: {
    ...mapMutations("generic", [
      "addEvent",
      "openModal",
      "hideModal",
      "removeLoading",
    ]),
    ...mapActions("generic", ["putAllApi", "deleteAllApi"]),
    generate() {
      let params = {
        url: this.urlGenerateAllApi,
        selected: [this.invoice.id],
      };
      this.putAllApi(params).then((response) => {
        if (response.success) {
          this.hideModal("generateInvoice");
          this.addEvent({
            name: "updateInvoice",
          });
        }
        this.removeLoading(["confirm"]);
      });
    },
    cancel() {
      let params = {
        url: this.urlCancelAllApi,
        selected: [this.invoice.id],
      };

      this.deleteAllApi(params).then((response) => {
        if (response.success) {
          this.addEvent({
            name: "updateInvoice",
          });
          this.hideModal("cancelInvoice");
        }
        this.removeLoading(["confirm"]);
      });
    },
    nextStep() {
      this.modalOptions = {
        name: "printRent",
        title: "Impressão",
        width: 1200,
      };
    },
    closeModal() {
      this.modalOptions = { name: "", title: "", width: 0 };
    },
    back() {
      this.modalOptions = {
        name: "selectDocument",
        title: "Selecione o Documento",
        width: 600,
      };
      this.removeLoading(["back"]);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "generateInvoice") {
          this.modalOptions = {
            name: "generateInvoice",
            title: "Tem certeza que deseja gerar?",
            width: 500,
          };
          this.openModal("optionsRent");
        }

        if (event.name == "cancelInvoice") {
          this.modalOptions = {
            name: "cancelInvoice",
            title: "Tem certeza que deseja cancelar?",
            width: 600,
          };
          this.openModal("optionsRent");
        }

        if (event.name == "printInvoice") {
          this.openModal("print");
        }
      },
      deep: true,
    },
  },
};
</script>
<style  scoped>
.select-document {
  min-height: 100px;
}

.icon-close {
  color: red;
}

.icon-warning {
  color: darkorange;
}
</style>